// ------------------------------
// 共通
// ------------------------------

html {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "Open Sans", sans-serif;
  /* 計算しやすいようにroot要素に10px相当のサイズを指定 */
  /*font-size: 62.5%;*/
  color: $color-base;
  height:100%;
}

body {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "Open Sans", sans-serif;
  margin: 0;
  width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "Open Sans", sans-serif;
  font-weight: normal;
}

.button {
  font-family: "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", "Open Sans", sans-serif;
  font-weight: normal;
}

// media queris
$pc-width:1024px;
@mixin SP {
  @media screen and (max-width: $pc-width - 1) {
    @content;
  }
}

@mixin PC {
  @media screen and (min-width: $pc-width) {
    @content;
  }
}
/*
ul {
    padding: 0;
    margin: 0;
}

li{
    list-style-type: none;
    list-style-position: outside;
    padding: 0;
}

h1,h2,h3,h4,figure,dl,dt,dd,ul,li {
    margin: 0;
}

img {
  vertical-align: bottom;
}*/
