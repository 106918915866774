@import "variable", "common", "modal";
@import "../../assets/stylesheets/modules/_company";

// ------------------------------
// パレクラ用
// ------------------------------

// リンク
.link {
  text-decoration: none;
  color: $color-base;
  cursor: pointer;

  &.link--complement {
    text-decoration: underline;
    color: $color-link;
  }
}

// インライン要素
.note {
  display: inline-block;
}

@media screen and (max-width: 375px) {
  .hide-on-xsmall {
    display: none;
  }
}

// ------------------------------
// セクションサイズ
// ------------------------------


.contents {
  width: 80vw;
  max-width: 960px;
  min-width: 320px;
  height: 28.125vw;
  margin: auto;
  display: flex;
  align-items: center;
  &--2x {
    width: 80vw;
    max-width: 960px;
    min-width: 320px;
    height: 56.25vw;
    margin: auto;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .contents {
    height: 37.5vw;
    &--2x {
      height: 112.5vw;
    }
  }
}

@media screen and (max-width: 800px) {
  .contents {
    width: 87.5vw;
    height: 66.6vw;
    &--2x {
      height: 166.5vw;
    }
  }
}

@media screen and (max-width: 540px) {
  .contents {
    min-width: 320px;
    height: 100vw;
    &--2x {
      height: 250vw;
    }
  }
}

@media screen and (max-width: 375px) {
  .contents {
    min-width: 320px;
    height: 120vw;
    &--2x {
      height: 300vw;
    }
  }
}


// ------------------------------
// フォントサイズ
// ------------------------------

// 1025〜
// 中サイズ
.font--middle {
  font-size: $font-middle;
  line-height: $font-middle * $line-height;
  letter-spacing: $font-middle * $letter-space-min;
}

//大サイズ
.font--large {
  font-size: $font-large;
  line-height: $font-large * $line-height;
  letter-spacing: $font-large * $letter-space;
}

// 小サイズ
.font--small {
  font-size: $font-small;
  line-height: $font-small * $line-height;
  letter-spacing: $font-small * $letter-space-min;
}

// 727〜1024
@media screen and (max-width: 1024px) {
  // 中サイズ
  .font--middle {
    font-size: $font-middle-tb;
    line-height: $font-middle-tb * $line-height;
    letter-spacing: $font-middle-tb * $letter-space-min;
  }

  // 大サイズ
  .font--large {
    font-size: $font-large-tb;
    line-height: $font-large-tb * $line-height;
    letter-spacing: $font-large-tb * $letter-space;
  }

  // 小サイズ
  .font--small {
    font-size: $font-small-tb;
    line-height: $font-small-tb * $line-height;
    letter-spacing: $font-small-tb * $letter-space-min;
  }
}

// 〜726
@media screen and (max-width: 726px) {
  // 中サイズ
  .font--middle {
    font-size: $font-middle-sp;
    line-height: $font-middle-sp * $line-height;
    letter-spacing: $font-middle-sp * $letter-space-min;
  }

  // 大サイズ
  .font--large {
    font-size: $font-large-sp;
    line-height: $font-large-sp * $line-height;
    letter-spacing: $font-large-sp * $letter-space;
  }

  // 小サイズ
  .font--small {
    font-size: $font-small-sp;
    line-height: $font-small-sp * $line-height;
    letter-spacing: $font-small-sp * $letter-space-min;
  }
}


// ------------------------------
// ボタン
// ------------------------------

.button {
  display: flex;
  align-items: center;
  font-size: $font-middle;
  line-height: $font-middle * $line-height;
  letter-spacing: $font-middle * $letter-space;
  text-indent:: $font-middle * $letter-space;
}

@media screen and (max-width: 1024px) {
  .button {
    font-size: $font-middle-tb;
    line-height: $font-middle-tb * $line-height;
    letter-spacing: $font-middle-tb * $letter-space;
    text-indent:: $font-middle-tb * $letter-space;
  }
}

@media screen and (max-width: 860px) {
  .button {
    font-size: $font-middle-sp;
    line-height: $font-middle-sp * $line-height;
    letter-spacing: $font-middle-sp * $letter-space;
    text-indent:: $font-middle-sp * $letter-space;
    height: 32px;
  }
}

.apply__button {
  display: inline-block;
  margin-top: 8px;
  .button {
    justify-content: center;
  }

  .apply__button__link--large {
    font-size: $font-large;
    line-height: $font-large * $line-height;
    letter-spacing: $font-large * $letter-space;
    text-indent:: $font-large * $letter-space;
    padding: 13px 40px;
  }

  @media screen and (max-width: 1024px) {
    .apply__button__link--large {
      font-size: $font-large-tb;
      line-height: $font-large-tb * $line-height;
      letter-spacing: $font-large-tb * $letter-space;
      text-indent:: $font-large-tb * $letter-space;
      padding: 10px 32px;
    }
  }

  @media screen and (max-width: 860px) {
    .apply__button__link--large {
      font-size: $font-large-sp;
      line-height: $font-large-sp * $line-height;
      letter-spacing: $font-large-sp * $letter-space;
      text-indent:: $font-large-sp * $letter-space;
      padding: 20px 26px;
    }
  }
}


// ------------------------------
// ホワイトボックス
// ------------------------------

.contents__container--separate {
  height: 28.125vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .contents__container--separate--left {
    width: 32%;

    .contents__container__summary__copy {
      margin: 32px 0;
    }

    .contents__container__summary__link {
      margin-bottom: 0;
    }
  }

  .contents__container__detail {
    width: 56%;
  }
}

@media screen and (max-width: 600px) {
  .contents__container--separate {
    height: 66.6vw;
    justify-content: center;
    flex-direction: column;
    margin: auto;

    .contents__container__summary {
      margin-bottom: 24px;

      .contents__container__summary__copy {
        margin: 16px 0;
      }
    }

    .contents__container--separate--left, .contents__container__detail {
      width: 87.5vw;
    }
  }
}

@media screen and (max-width: 488px) {
  .contents__container--separate {
    height: 100vw;
  }
}

@media screen and (max-width: 375px) {
  .contents__container--separate {
    height: 120vw;
  }
}

@media screen and (max-width: 336px)  {
  .contents__container--separate {

    .contents__container__summary {
      margin-bottom: 20px;

      .contents__container__summary__copy {
        margin: 12px 0;
      }
    }
  }
}


// ------------------------------
// アイコン
// ------------------------------

.contents__container__detail__icons {
  margin: 24px -1em 0;
  display: flex;
  justify-content: space-between;

  .contents__container__detail__icon__container {
    text-align: center;
    width: 200px;

    .contents__container__detail__icon__container__img {
      margin: 0 auto 8px;
      cursor: pointer;

      .contents__container__detail__icon__container__img__icon {
        width: 30px;
        height: 30px;
      }

      @media screen and (max-width: 856px) {
        .contents__container__detail__icon__container__img__icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    .contents__container__detail__icon__container__text {
      margin: 0;
      padding: 0;
      letter-spacing: 0;

      @media screen and (max-width: 375px) {
        padding: 0 .5em;
      }
    }
  }
}

@media screen and (max-width: 336px) {
  .contents__container__detail__icons {
    margin-top: 16px;
  }
}


// ------------------------------
// セクション間のイメージ
// ------------------------------

.interval__image {
  width: 100%;
  height: 56.25vw;

  .interval__image__img {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .interval__image {
    height: 66.6vw;

    .interval__image__img {
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 488px) {
  .interval__image {
    height: 100vw;
  }
}


// ------------------------------
// 以下、要素ごとの調整
// ------------------------------

// ------------------------------
// ヘッダー
// ------------------------------

// ヘッダー幅
.header {
  width: 100%;
  height: 6.25vw;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  z-index: 100;
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
  &.show{
    transform: translateY(0%);
    background: rgba(255, 255, 255, .9);
  }

  .header__container {
    width: 96.67vw;
    margin: 16px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media screen and (max-width: 768px) {
  .header {
    height: 10vw;
    background: rgba(255, 255, 255, 0.9);

    .header__container {
      margin: 8px auto;
    }
  }
}

// ロゴ
.header__container__title {
  width: 24vw;
  margin-bottom: 0;
  line-height: 0;
}

@media screen and (max-width: 768px) {
  .header__container__title {
    width: 40vw;
  }
}

// メニュー
.header__container__menu {
  transition: all 0.3s ease-in-out;

  &.show {
    background: rgba(255, 255, 255, .9);
  }

  .header__container__menu__list {

    .header__container__menu__list__item {
      display: inline-block;
      margin-right: 48px;

      &:last-child {
        margin-right: 4px;
      }
    }
  }
}
.header__container__btn {
  display: none;
}

@media screen and (max-width: 768px) {

  .header__container__menu {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
    transform: translateY(100%);
    z-index: -1;
    overflow: hidden;
    .header__container__menu__list{
      transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
      margin-top: -200px;
      opacity: 0;
    }
    &.show{
      .header__container__menu__list{
        margin-top: 0px;
        opacity: 1;
      }
    }

    .header__container__menu__list__item{
      display: block;
      width: 100%;
      text-align: center;
      font-size: 14px;
      padding: 10px;
    }

  }
  .header__container__btn {
    display: block;
  }
}

%menu-bar{
  content:"";
  position: absolute;
  height: 1px;
  width: 100%;
  background-color: $color-base;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
}
.header__container__btn{
  position: relative;
  width: 16px;
  height: 14px;
  margin-right: 5px;

  &:before{
    @extend %menu-bar;
    top: 0;
    left: 0;
    opacity: 1;
  }

  &:after{
    @extend %menu-bar;
    bottom: 0;
    left: 0;
    opacity: 1;
  }

  .center-bar{
    &:before{
      @extend %menu-bar;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }

    &:after{
      @extend %menu-bar;
      transform: translateY(-50%);
      top: 50%;
      left: 0;
    }
  }

  &.show{
    &:before, &:after{
      opacity: 0;
    }

    .center-bar{
      &:before{
        transform: translateY(-50%) rotate(45deg);
      }

      &:after{
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
}

// スマホ用メニューオープン時の背景オーバーレイ

#header-overlay {
  z-index: 4;

  &.show {
  position: fixed;
  background: #000;
  opacity: 0.3;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: all 0.3s cubic-bezier(0.65, 0.05, 0.36, 1);
  }
}


// ------------------------------
// トップ
// ------------------------------

.top {
  @media screen and (max-width: 768px) {
    background: url(../../assets/images/top/top@SP.jpg) top center no-repeat;
    background-size: cover;
  }
  background: url(../../assets/images/top/top.jpg) top center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .top__button {
    position: absolute;
    right: 2vw;
    top: 2vw;

    .top__button__link {
      color: $color-bgblack;
      border-color: $color-bgblack;
      display: inline-flex;
      align-items: center;
      line-height: 1;
      min-height: auto;
      @media screen and (max-width: 860px) {
        padding: 0 20px;
      }

      &:first-child {
        margin-right: 2vw;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    .top__button {
      right: 1vw;
    }
  }

  .top__img {
    width: 64%;
    margin: 0 auto 8px;
  }

  .top__text {
    width: 80vw;
    margin: auto;
    color: $color-bgblack;
    text-shadow: 0 0 5.54px rgba(0, 0, 0, 0.7);
    font-size: $font-large;
    line-height: 65px;
    letter-spacing: 8.5px;
    text-align: center;
  }
}

@media screen and (max-width: 640px) {
  .top {

    .top__img {
      width: 90%;
    }

    .top__text {
      font-size: $font-large-sp;
      line-height: 35px;
      letter-spacing: 5px;
    }
  }

  @media screen and (max-width: 375px) {
    .top {
      .top__text {
        letter-spacing: 3px;
      }
    }
  }
}


// ------------------------------
// .intro
// ------------------------------

.intro {
  text-align: left;

  .contents__container {
    width: 100%;
    margin: auto;
  }

  .intro__contents__copy {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 600px) {
  .intro {

    .contents__container {
      width: 87.5vw;
      margin: auto;
    }
  }
}


// ------------------------------
// .services
// ------------------------------

.services {
  width: 80vw;
  max-width: 960px;
  @media screen and (max-width: 375px) {
    min-width: 320px;
  }
  margin: 50px auto;

  .contents__container {
    width: 100%;
    margin: auto;

    @media screen and (max-width: 600px) {
      width: 87.5vw;
      margin: auto;
    }
  }

  ul {
    margin: 0;
    border-top: 1px solid #D5D5D5;
    li.row {
      margin: 0;
      padding: 1rem 0;
      border-bottom: 1px solid #D5D5D5;
      .services__right-col {
        display: flex;
        align-items: center;
      }
      .services__left-col {
        font-size: $font-middle;
        line-height: $font-middle * $line-height;
        letter-spacing: $font-middle * $letter-space;
        @media screen and (max-width: 726px) {
          font-size: $font-middle-sp;
          line-height: $font-middle-sp * $line-height * 1.25;
          letter-spacing: $font-middle-sp * $letter-space-min;
        }
      }
    }
  }

  .services__logo {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    .services__logo__img {
      max-height: none;
      height: 40px;
      @media screen and (max-width: 768px) {
        margin: 0 0 .6rem;
      }
    }
  }

  .services__detail {
    h4 {
      margin-top: 3rem;
      font-size: $font-middle;
      @media screen and (max-width: 768px) {
        font-size: $font-middle-sp;
      }
    }

    .services__list {
      margin-top: 4rem;
    }

    .contents__container__detail__span {
      vertical-align: top;
    }
  }
}

.table__row {

  &:last-child {
    border-bottom: none;
  }

  .table__heading {
    width: 28%;
    padding: 8px 8px 8px 0;
    vertical-align: top;
    font-weight: normal;
    @media screen and (max-width: 768px) {
      cursor: pointer;
      &.active {
        background-color: $color-base;
        color: $color-bgblack;
      }
    }
  }

  .table__data {
    width: auto;
    padding: 8px 16px;
  }
}

.table__row--last {
  .table__cell {
    border-bottom: none;
  }
}

.link--CEOmessage {
  margin-left: $font-middle;
}

// ------------------------------
// .end
// ------------------------------

.end {
  text-align: center;

  .contents__container {
    margin: auto;

    .end__logo__container {
      width: 100%;
      margin: 0 auto 16px;
    }
  }
}

@media screen and (max-width: 600px) {
  .end {

    .contents__container {
      width: 87.5vw;
    }
  }
}


// ------------------------------
// .footer
// ------------------------------

.footer {
  width: 80vw;
  text-align: center;
  display: flex;
  align-items: flex-end;
  margin: 5em auto 1em;

  .footer__contents {
    width: 100%;
    margin: 0 auto;
  }

  @media screen and (max-width: 648px) {
    .footer {
      width: 87.5vw;
    }
  }

  @media screen and (max-width: 488px) {
    .footer {
      min-width: 320px;
    }
  }

  @media screen and (max-width: 375px) {
    .footer {
      min-width: 320px;
    }
  }

  .partner__logo__container {
    margin-bottom: 24px;
    width: 100%;

    .partner__logo__img {
      display: inline-block;
      height: 40px;
      margin: 0.5em;
    }
  }

  @media screen and (max-width: 1050px) {
    .partner__logo__container {
      margin-bottom: 0;
    }
  }

  .contents__container__detail__text--termpolicy {
    margin-top: 2rem;
  }

  .sns__container {
    width: 50%;
    height: 50px;
    margin: 0 auto 24px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    .sns__container__button {
      display: inline-block;
      height: 30px;
    }
  }

  // B向けだけ追加
  @media screen and (max-width: 600px) {
    .sns__container {
      width: 100%;
    }
  }
}

@media screen and (max-width: 600px) {
  .footer {

    .contents__container {
      width: 87.5vw;
      margin: auto;
    }
  }
}

.footer__contents__copyright {
  display: block;
  margin-bottom: 16px;
}


// ------------------------------
// 料金プラン
// ------------------------------

// ------------------------------
// .outline
// ------------------------------

.outline--price {

  .outline__contents {
    width: 80vw;
  }

    .outline__logo {
      height: 100%;
      display: flex;
      align-items: center;

      .outline__logo__img {
        height: 72%;
        width: auto;
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 600px) {
      .outline__logo {
        height: 48vw;
        margin-bottom: 16px;

        .outline__logo__img {
          height: 80%;
        }
      }
    }

    @media screen and (max-width: 448px) {
      .outline__logo {
        margin-bottom: 8vw;

        .outline__logo__img {
          height: 85%;
        }
      }
    }

    @media screen and (max-width: 340px) {
      .outline__logo {
        margin-bottom: 16px;
      }
    }

    .outline__detail {

      .contents__container__detail__text {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 600px) {
      .outline__detail {
        text-align: center;
      }
    }
}

// ------------------------------
// .plan
// ------------------------------

.plan {
  width: 80vw;
  max-width: 960px;
  margin: 40px auto;
}

.plan__contents__table {

  .table__th--heading {
    vertical-align: top;
  }

  .table__td--nextth {
    padding-left: 0;
  }

  .table__cell__heading--width {
    width: 15%;
  }

  @media screen and (max-width: 768px) {

    .table__row {
      display: flex;
      flex-wrap: wrap;
    }

    .table__cell__heading--width {
      width: 100%;
      display: inline-block;
    }
  }

  .table__cell--width {
    width: 21.25%;

    &.table__cell--align {
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    .table__cell--width {
      width: 50%;
      display: inline-block;
    }

    .table__heading {
      width: 33.3%;
    }
  }
}


// ------------------------------
// 料金プラン
// ------------------------------

// ------------------------------
// .outline
// ------------------------------

.outline--news {

  .outline__contents {
    width: 80vw;
  }

    .outline__logo {
      height: 100%;
      display: flex;
      align-items: center;

      .outline__logo__img {
        height: 72%;
        width: auto;
        margin: 0 auto;
      }
    }

    @media screen and (max-width: 600px) {
      .outline__logo {
        height: 48vw;
        margin-bottom: 16px;

        .outline__logo__img {
          height: 80%;
        }
      }
    }

    @media screen and (max-width: 448px) {
      .outline__logo {
        margin-bottom: 8vw;

        .outline__logo__img {
          height: 85%;
        }
      }
    }

    @media screen and (max-width: 340px) {
      .outline__logo {
        margin-bottom: 16px;
      }
    }

    .outline__detail {

      .contents__container__detail__text {
        margin-bottom: 0;
      }
    }

    @media screen and (max-width: 600px) {
      .outline__detail {
        text-align: center;
      }
    }
}

// ------------------------------
// .news
// ------------------------------

.news {
  width: 80vw;
  max-width: 960px;
  margin: 40px auto;
}

.news__contents__table {

  .table__th--heading {
    vertical-align: top;
  }

  .table__td--nextth {
    padding-left: 0;
  }

  .table__cell__heading--width {
    width: 15%;
  }

  @media screen and (max-width: 768px) {

    .table__row {
      display: flex;
      flex-wrap: wrap;
    }

    .table__cell__heading--width {
      width: 100%;
      border-bottom: none;
    }
  }

  .table__cell--width {

    &.table__cell--align {
      text-align: center;
    }
  }

  @media screen and (max-width: 768px) {
    .table__cell--width {
      width: 100%;
      padding-top: 0;
      padding-left: 0;
    }
  }

  .table__row:last-child {

    .table__cell__heading--width {
      border-bottom: none;
    }

    .table__cell--width {
      border-bottom: none;
    }
  }
}

.news__contents {

  .apply__button {
    margin: auto;
    display: table;
  }
}
// ------------------------------
// .recruit
// ------------------------------

.recruit {
  width: 80vw;
  max-width: 960px;
  margin: 40px auto;
  .recruit__tabs {
    position: relative;
    width: calc(100% + 1px);
    z-index: 10;
    @include SP{
      width: 100%;
      margin-bottom: 0;
    }
    ul {
      flex-direction: row;
      flex-wrap: wrap;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      li {
        width: 100%;
        @include SP{
          display: inline-block;
          width: 50%;
        }
        a {
          @include SP{
            padding: 7px 10px;
          }
        }
        &.active {
          a {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            border-right: 1px solid white;
            background-color: white;
            @include SP{
              border: 1px solid rgba(0, 0, 0, 0.1);
            }
          }
        }
      }
    }
  }
  .recruit__main {
    padding: .5em 1em 0;
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    background-color: white;
    @include SP{
      border-left: none;
      padding: .5em 0;
    }
  }
}

.recruit__contents {
  .apply__button {
    margin: 2em auto 1em;
    display: table;
  }
}


// ------------------------------
// error
// ------------------------------
section.error {
  color: #000;
  .contents__container {
    padding: 1rem;
  }
}


// ------------------------------
// IE9以下
// ------------------------------

#ie9 {
  text-align: center;
  margin: auto;
  background-color: #EFEFEF;
  padding: 60px 30px;
  width: 100vw;
  height: 100vh;

  .img--error {
    width: 100px;
    height: 100px;
    display: block;
    margin: auto auto 50px;
  }

  .font--large {
    margin: auto auto 30px;
  }
}

// ------------------------------
// No Script Alert
// ------------------------------

.noscript-alert {
  position: absolute;
  top: 150px;
  left: 10%;
  width: 80%;
  padding: 1em;
  background-color: rgba(255, 255, 255, .7);
  border-radius: 2px;
  z-index: 100;
}
