// ------------------------------
// 変数
// ------------------------------

$font-large: 24px;
$font-middle: 16px;
$font-small: 12px;
$font-large-tb: 20px;
$font-middle-tb: 13.5px;
$font-small-tb: 10px;
$font-large-sp: 16px;
$font-middle-sp: 12px;
$font-small-sp: 8px;
$line-height: 1.6;
$letter-space: 0.2;
$letter-space-min: 0.1;
$color-link: #888;
$color-base: #333;
$color-bgblack: #fff;
