// ------------------------------
// モーダル
// ------------------------------

.link--modal {

  &::after {
    display: inline-block;
    content: "";
    width: $font-middle - 2px;
    height: $font-middle - 2px;
    background: url(../images/icon_modal.svg) no-repeat center bottom;
    margin-left: 4px;
  }
}

// モーダルのoverlayにふわっと追加
.modal-overlay {
  opacity: 0;
  display: none;
  transition: all .5s ease-out;

  &.show {
    display: block !important;
    opacity: 1;
  }
}

// モーダルがopenになったときだけ#mainに適用させる
.main--modal--overlay {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

// モーダルコンテンツ部分

.modal {
  top: -3em;
  width: 95vw !important;
  margin: auto !important;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: all .5s ease-out;
  @media screen and (max-width: 480px) {
    height: 96vh;
  }

  .modal-pager {
    margin-bottom: .5em;
    text-align: center;
  }

  &.open {
    top: 0;
    opacity: 1;
  }

  .modal-header {
    padding-bottom: 0;
    margin: 0 auto;
    font-weight: normal;
    border-bottom: none;
    color: $color-link;
  }

  .modal-body {
    padding-top: 24px;
    display: flex;
    justify-content: space-between;
    height: 100%;
  }

  @media screen and (max-width: 900px) {
    .modal-body {
      flex-direction: column;
      max-height: 80vh;
      margin-bottom: .5em;
      overflow: scroll;

      .modal-body__text {
        margin: auto;
      }
    }
  }


  .modal-body__text {
    width: 65%;
  }

  @media screen and (max-width: 590px) {
    .modal-body__text {
      margin: auto;
      width: 70%;
    }
  }

  @media screen and (max-width: 480px) {
    .modal-body__text {
      margin: auto;
      width: 80%;
    }
  }

  @media screen and (max-width: 420px) {
    .modal-body__text {
      margin: auto;
      width: 90%;
    }
  }

  @media screen and (max-width: 370px) {
    .modal-body__text {
      margin: auto;
      width: 95%;
    }
  }


  .modal-body__img {
    padding: 0 32px 0 0;
    text-align: center;
    width: 50%;
    height: auto;

    @media screen and (max-width: 900px) {
      width: 75%;
      padding: 0;
      margin: auto;
    }

    @media screen and (max-width: 480px) {
      width: 95%;
      padding: 0;
      margin: auto;
    }
  }

  .link--complement {
    margin-top: 2rem;
  }
}

.modal {
  .close {
    opacity: 1;
  }
}

.button__page {
  width: 15px;
  height: 15px;
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 2em 1em;
  cursor: pointer;

  @media screen and (max-width: 400px) {
    width: 12px;
    height: 12px;
  }

  &::before {
    display: block;
    position: absolute;
    content: "";
    width: inherit;
    height: inherit;
    top: 1.5em;
    border-top: 4px solid #000;
    border-right: 4px solid #000;

    @media screen and (max-width: 400px) {
      border-top: 2px solid #000;
      border-right: 2px solid #000;
    }
  }

  &.page--prev {
    left: 0;

    &::before {
      left: 50%;
      transform: rotate(225deg);
    }
  }

  &.page--next {
    right: 0;

    &::before {
      right: 50%;
      transform: rotate(45deg);
    }
  }
}

.hide {
  display: none !important;
}

.modal-flex{
  display: flex;
  align-items: center;
}

// ------------------------------
// メッセージ
// ------------------------------

#modal--message {

  .modal-body__text {
    width: 100%;

    .modal-body__text--title {
      margin: 2rem 0;
    }

    .modal-body__text__p--last {
      margin: 2rem 0 0;
      text-align: right;
    }
  }
}

// ------------------------------
// お問い合わせ
// ------------------------------

#modal--contact {

  .modal-body {
    width: 80%;
    margin: auto;

    .modal-body__contents {
      text-align: center;
      width: 100%;

      .modal-body__contents__button {
        margin-top: 2rem;

        .apply__button {
          display: table;
          margin: 2rem auto 0;
        }
      }
    }
  }
}
